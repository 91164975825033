<template>
  <div id="app">
    <GameLandingPage />
  </div>
</template>

<script>
import GameLandingPage from './components/GameLandingPage.vue'

export default {
  name: 'App',
  components: {
    GameLandingPage
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

:root {
  --primary-color: #1e88e5;
}
</style>
