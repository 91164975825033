<template>
  <div class="container">
    <!-- 按顺序排列图片 -->
    <div class="image-container">
      <img :src="getImagePath('image1')" alt="游戏图片1" class="full-width-image no-drag" @click="openFirstImageLink" @error="handleImageError">
      <img :src="getImagePath('image2')" alt="游戏图片2" class="full-width-image no-drag" @error="handleImageError">
      <img :src="getImagePath('image3')" alt="游戏图片3" class="full-width-image no-drag" @error="handleImageError">
      <img :src="getImagePath('image4')" alt="游戏图片4" class="full-width-image no-drag" @error="handleImageError">
      <div class="customer-service">
        <button class="cs-btn" @click="contactCustomerService">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="no-drag">
            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
          </svg>
          <div class="cs-text">{{ customerServiceText }}</div>
        </button>
      </div>
    </div>
    
    <!-- 底部悬浮菜单 -->
    <div class="floating-menu">
      <div class="btn-container">
        <div class="tip-bubble">{{ playTipText }}</div>
        <button class="btn play-btn" @click="startGame">{{ playButtonText }}</button>
      </div>
      <div class="btn-container">
        <div class="tip-bubble">{{ downloadTipText }}</div>
        <button class="btn download-btn" @click="downloadApp">{{ downloadButtonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameLandingPage',
  data() {
    return {
      // 默认文本，如果配置文件中没有定义则使用这些默认值
      playButtonText: '开始游戏',
      downloadButtonText: '下载APP',
      playTipText: '推荐入口',
      downloadTipText: '下载APP秒领23元',
      customerServiceText: '联系客服',
      // 默认图片路径
      defaultImagePaths: {
        image1: '/1.avif',
        image2: '/2.avif',
        image3: '/3.avif',
        image4: '/4.avif'
      }
    }
  },
  created() {
    // 从配置文件加载文本内容
    this.loadConfigTexts();
  },
  methods: {
    // 获取图片路径，如果配置中没有则使用默认路径
    getImagePath(imageKey) {
      const images = this.getConfigValue('images');
      if (images && images[imageKey]) {
        return images[imageKey];
      }
      return this.defaultImagePaths[imageKey];
    },
    // 处理图片加载错误
    handleImageError(e) {
      console.warn(`图片加载失败: ${e.target.src}`);
      // 可以设置一个默认图片或者其他处理方式
      e.target.style.display = 'none'; // 隐藏加载失败的图片
    },
    loadConfigTexts() {
      // 检查 window.gameConfig 是否存在
      if (typeof window.gameConfig !== 'undefined' && window.gameConfig) {
        // 加载按钮文本
        if (window.gameConfig.playButtonText) {
          this.playButtonText = window.gameConfig.playButtonText;
        }
        if (window.gameConfig.downloadButtonText) {
          this.downloadButtonText = window.gameConfig.downloadButtonText;
        }
        
        // 加载提示文本
        if (window.gameConfig.playTipText) {
          this.playTipText = window.gameConfig.playTipText;
        }
        if (window.gameConfig.downloadTipText) {
          this.downloadTipText = window.gameConfig.downloadTipText;
        }
        
        // 加载客服文本
        if (window.gameConfig.customerServiceText) {
          this.customerServiceText = window.gameConfig.customerServiceText;
        }
      } else {
        console.warn('警告: window.gameConfig 未定义，使用默认文本');
      }
    },
    openFirstImageLink() {
      this.logAndOpen('打开第一张图片链接', 'firstImageLink');
    },
    startGame() {
      this.logAndOpen('开始游戏', 'playGameLink');
    },
    downloadApp() {
      this.logAndOpen('下载APP', 'downloadAppLink');
    },
    contactCustomerService() {
      this.logAndOpen('联系客服', 'customerServiceLink');
    },
    // 安全地获取配置值
    getConfigValue(key) {
      return (typeof window.gameConfig !== 'undefined' && window.gameConfig && window.gameConfig[key]) 
        ? window.gameConfig[key] 
        : null;
    },
    // 随机获取链接
    getRandomLink(linkKey) {
      const links = this.getConfigValue(linkKey);
      
      // 如果链接不存在或不是数组，返回 null
      if (!links || !Array.isArray(links) || links.length === 0) {
        return null;
      }
      
      // 从数组中随机选择一个链接
      const randomIndex = Math.floor(Math.random() * links.length);
      return links[randomIndex];
    },
    logAndOpen(action, linkKey) {
      // 从配置的链接数组中随机获取一个链接
      const url = this.getRandomLink(linkKey);
      
      if (this.getConfigValue('debug')) {
        console.log(`${action}: ${url}`);
      }
      
      if (url) {
        window.open(url, '_blank');
      } else {
        console.warn(`${action}: 未配置URL或链接数组为空`);
      }
    }
  }
}
</script>

<style scoped>
@import '../assets/style.css';

.full-width-image {
  cursor: pointer;
}

/* 禁止拖拽和选择 */
.no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: auto !important;
}

/* 确保页面内容固定 */
.container {
  overflow-x: hidden;
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}

.image-container {
  position: relative;
  overflow: hidden;
}
</style> 