import { createApp } from 'vue'
import App from './App.vue'

// 确保 window.gameConfig 存在
if (typeof window.gameConfig === 'undefined') {
  console.warn('window.gameConfig 未定义，使用默认配置');
  window.gameConfig = {
    // 默认的配置对象
    firstImageLink: [],
    customerServiceLink: [],
    playGameLink: [],
    downloadAppLink: [],
    playButtonText: "开始游戏",
    downloadButtonText: "下载APP",
    playTipText: "推荐入口",
    downloadTipText: "下载APP秒领23元",
    customerServiceText: "联系客服",
    // 添加图片路径配置项
    images: {
      image1: "/1.avif",
      image2: "/2.avif",
      image3: "/3.avif",
      image4: "/4.avif"
    },
    debug: false
  };
}

createApp(App).mount('#app')
